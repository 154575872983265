import * as React from "react";
import { DashboardApiClient } from "Components/Home/dashboardApiClient";
import { Dialog } from "spry-react/Dialog/dialog";
import { ErrorDisplayListAutoScrolling } from "spry-react/Errors/errorDisplayListAutoScrolling";
import { LabeledMaskedTextField } from "spry-react/Text/maskedTextField";
import { useState } from "react";
import { ErrorList } from "spry-react/Errors/errorList";
import { LabeledTextField } from "spry-react/Text/textField";
import { Button } from "spry-react/Buttons/button";
import { IAppContext } from "Components/Shared/appContext";
import { LoggedInUser } from "Components/Shared/Layout/authenticatedUserLayout";
import { PureG, PureU, Field, PureGU1Field } from "Components/Shared/pureG";
import { setErrorsFromResponse } from "Components/Shared/setErrorsFromResponse";
import ReCAPTCHA from "react-google-recaptcha";

export function RequestClaimAccessDialog(props: {
  closeDialog: () => void;
  appContext: IAppContext;
  apiClient: DashboardApiClient;
  portalUser: LoggedInUser;
  claimNumberInputMask: string;
  recaptchaPublicSiteKey: string;
}){
  const { closeDialog, portalUser, apiClient, appContext, claimNumberInputMask, recaptchaPublicSiteKey } = props;
  const [ claimNumber, setClaimNumber ] = useState("");
  const [ victimName, setVictimName ] = useState("")
  const [ representingClaimantName, setRepresentingClaimantName ] = useState("");
  const [ recaptchaResponse, setRecaptchaResponse ] = useState("");
  const [ errors, setErrors ] = useState(new ErrorList([]));

  return <>
    <Dialog singleTabText="Request Access">
    <PureG>
      <PureU size="1">
        <ErrorDisplayListAutoScrolling errors={errors} />
      </PureU>
    </PureG>
    <PureG className="l-box-vt-2x">
      <Field size="1-3">
        <LabeledMaskedTextField label="Claim Number" required value={claimNumber} mask={claimNumberInputMask} onChange={setClaimNumber} />
      </Field>
      {
        portalUser.isVictimClaimant 
          ? <Field size="2-3">
              <LabeledTextField label="Victim Name" required value={victimName} onChange={setVictimName} />
            </Field>
          : <>
            <Field size="1-3">
              <LabeledTextField label="Victim Name" required value={victimName} onChange={setVictimName} />
            </Field>
            <Field size="1-3">
              <LabeledTextField label="Representing Claimant Name" required value={representingClaimantName} onChange={setRepresentingClaimantName} />
            </Field>
          </>
      }
    </PureG>
    <PureGU1Field>
      <ReCAPTCHA sitekey={recaptchaPublicSiteKey} onChange={setRecaptchaResponse} />
    </PureGU1Field>
    <div className='l-box-vt action-buttons'>
      <Button color="transparent" onClick={closeDialog}>
        Cancel
      </Button>
      <Button 
        color="primary"
        onClick={() => {
          apiClient.createAccessRequest({ 
            portalUserId: portalUser.userId,
            claimNumber: claimNumber, 
            victimName: victimName,
            representingClaimantName: representingClaimantName,
            recaptchaResponse: recaptchaResponse
          })
          .then((r) => {
            if(setErrorsFromResponse(setErrors, r))
              return;

            appContext.flashMessage("Access request submitted.")
            closeDialog();
          });
        }}>
          Request Access
      </Button>
    </div>
    </Dialog>
  </>
}

export function RequestClaimAccessSection(props: { 
  portalUser: LoggedInUser,
  apiClient: DashboardApiClient,
  appContext: IAppContext,
  claimNumberInputMask: string;
  recaptchaPublicSiteKey: string;
}){
  const { portalUser, apiClient, appContext, claimNumberInputMask, recaptchaPublicSiteKey } = props;
  const [ isRequestSubmitted, setIsRequestSubmitted ] = useState(false);

  return <>
    <div>
      <h1>Request access to claim</h1>
      <p style={{fontSize: "1.4rem"}}>
        {
          portalUser.isVictimClaimant 
            ? "If you previsouly mailed in your claim application and want to track its progress online, add supporting information or file a supplemental claim, enter the claim number and the name of the victim.  Then click Request Access.  An investigator will review your request and respond shortly."
            : "If you previsouly mailed in your claim application and want to track its progress online, add supporting information or file a supplemental claim, enter the claim number, the name of the victim, and the name of the claimant you are representing.  Then click Request Access.  An investigator will review your request and respond shortly."
        }
        
      </p>
    </div>
    <div aria-live="polite">
      {
        !isRequestSubmitted 
          ? <RequestClaimAccessForm
              portalUser={portalUser}
              appContext={appContext} 
              apiClient={apiClient} 
              claimNumberInputMask={claimNumberInputMask}
              recaptchaPublicSiteKey={recaptchaPublicSiteKey}
              onRequestSubmitted={() => setIsRequestSubmitted(true)}
            />
          : <AccessRequestSubmitted 
              onRequestAccessToAnotherClaim={() => setIsRequestSubmitted(false)} 
            />
      }
    </div>
  </>
}

export function AccessRequestSubmitted(props: {
  onRequestAccessToAnotherClaim: () => void
}){
  return <>
    <div className="l-box-vt">
      <h3 className="l-box-vb">
        Your access request has been submitted.
      </h3>
      <Button 
        color="primary"
        onClick={props.onRequestAccessToAnotherClaim}>
        Request access to another claim
      </Button>
    </div>
  </>
}

export function RequestClaimAccessForm(props: { 
  portalUser: LoggedInUser,
  apiClient: DashboardApiClient,
  appContext: IAppContext,
  claimNumberInputMask: string;
  recaptchaPublicSiteKey: string;
  onRequestSubmitted: () => void;
}){
  const { portalUser, apiClient, appContext, claimNumberInputMask, onRequestSubmitted, recaptchaPublicSiteKey } = props;
  const [ claimNumber, setClaimNumber ] = useState("");
  const [ victimName, setVictimName ] = useState("")
  const [ representingClaimantName, setRepresentingClaimantName ] = useState("");
  const [ recaptchaResponse, setRecaptchaResponse ] = useState("");
  const [ errors, setErrors ] = useState(new ErrorList([]));

  return <>
    <PureG>
      <PureU size="1">
        <ErrorDisplayListAutoScrolling errors={errors} />
      </PureU>
    </PureG>
    <PureG className="l-box-vt-2x">
      <Field size="1-3">
        <LabeledMaskedTextField label="Claim Number" required value={claimNumber} mask={claimNumberInputMask} onChange={setClaimNumber} />
      </Field>
      {
        portalUser.isVictimClaimant 
          ? <Field size="2-3">
              <LabeledTextField label="Victim Name" required value={victimName} onChange={setVictimName} />
            </Field>
          : <>
            <Field size="1-3">
              <LabeledTextField label="Victim Name" required value={victimName} onChange={setVictimName} />
            </Field>
            <Field size="1-3">
              <LabeledTextField label="Representing Claimant Name" required value={representingClaimantName} onChange={setRepresentingClaimantName} />
            </Field>
          </>
      }
    </PureG>
    <PureGU1Field>
      <ReCAPTCHA sitekey={recaptchaPublicSiteKey} onChange={setRecaptchaResponse} />
    </PureGU1Field>
    <div className='l-box-vt' role="navigation">
      <Button 
        color="primary"
        onClick={() => {
          apiClient.createAccessRequest({ 
            portalUserId: portalUser.userId,
            claimNumber: claimNumber, 
            victimName: victimName,
            representingClaimantName: representingClaimantName,
            recaptchaResponse: recaptchaResponse
          })
          .then((r) => {
            if(setErrorsFromResponse(setErrors, r))
              return;

            appContext.flashMessage("Access request submitted.");
            onRequestSubmitted();
          })
        }}>
          Request Access
      </Button>
    </div>
  </>
}
