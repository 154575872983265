import * as React from "react";
import * as classNames from "classnames";
import { produce } from "immer";

export type PureSize =
'1'
| '1-1'
| '1-5'
| '2-5'
| '3-5'
| '4-5'
| '5-5'
| '1-24'
| '1-12'
| '2-24'
| '3-24'
| '1-8'
| '4-24'
| '1-6'
| '5-24'
| '1-4'
| '6-24'
| '7-24'
| '1-3'
| '8-24'
| '3-8'
| '9-24'
| '5-12'
| '10-24'
| '11-24'
| '1-2'
| '12-24'
| '13-24'
| '7-12'
| '14-24'
| '5-8'
| '15-24'
| '2-3'
| '16-24'
| '17-24'
| '3-4'
| '18-24'
| '19-24'
| '5-6'
| '20-24'
| '7-8'
| '21-24'
| '11-12'
| '22-24'
| '23-24'
| '24-24';

export class PureG extends React.Component<{className?: string}, {}> {
  render() {
    return <div className={classNames('pure-g', this.props.className)}>
      {this.props.children}
    </div>;
  }
}

export interface PureUProps {
  size?: PureSize;
  offset?: PureSize;
  alignRight?: boolean;
  className?: string;
  title?: string;
}

export class PureU extends React.Component<PureUProps, {}> {
  render() {
    const { size, children, alignRight, offset } = this.props;
    const sizeClass = size ? `pure-u-${size}` : 'pure-u-1';
    const offsetClass = offset ? `pure-offset-${offset}` : undefined;
    return <div className={classNames(sizeClass, offsetClass, this.props.className, { 'align-right': alignRight })}>
      {children}
    </div>;
  }
}

export interface FieldProps extends PureUProps {
  inline?: boolean;
  style?: React.CSSProperties;
}

export class Field extends React.Component<FieldProps, {}> {
  render() {
    const { inline, size, children, style, alignRight, offset, className, title } = this.props;
    const sizeClass = size ? `pure-u-${size}` : 'pure-u-1';
    const offsetClass = offset ? `pure-offset-${offset}` : undefined;
    const inlineClass = inline ? 'field-inline' : '';
    const classes = classNames('field', 'field-h', sizeClass, offsetClass, inlineClass, { 'align-right': alignRight }, className);

    let styles = style;
    if (inline) {
      styles = style ? produce(style, style => { style.paddingBottom = '1rem'; }) : { paddingBottom: '1rem' };
    }

    return <div className={classes} style={styles} title={title ? title : ''}>
      {children}
    </div>;
  }
}

export class PureGU1Field extends React.Component<{}, {}> {
  render() {
    return <div className='pure-g'>
      <div className='pure-u-1 field field-h'>
        {this.props.children}
      </div>
    </div>;
  }
}


export class PureGU1 extends React.Component<{}, {}> {
  render() {
    return <div className='pure-g'>
      <div className='pure-u-1'>
        {this.props.children}
      </div>
    </div>;
  }
}