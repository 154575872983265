import * as React from 'react';
import { Dialog } from 'spry-react/Dialog/dialog';
import { ErrorList } from 'spry-react/Errors/errorList';
import { TabList } from 'spry-react/Tabs/tabList';
import { LabeledTextField } from 'spry-react/Text/textField';
import { ErrorDisplayListAutoScrolling } from 'spry-react/Errors/errorDisplayListAutoScrolling';
import { FlashMessageCallback } from 'spry-react/App/app';
import { AccountsApiClient } from './accountsApiClient';
import { PureGU1Field } from 'Components/Shared/pureG';

interface Props {
  passwordPolicyRules: string[];
  closeDialog: () => void;
  accountsApiClient: AccountsApiClient;
  flashMessageCallback: FlashMessageCallback;
}

interface State {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  errors: ErrorList;
}

export class ChangePasswordDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      errors: new ErrorList([])
    };
  }

  render() {
    const state = this.state;
    const { passwordPolicyRules } = this.props;
    const errors = state.errors;

    const tabKey = "changePassword";
    const tabs = [
      {
        key: tabKey,
        text: "Change Password"
      }
    ];

    return <Dialog width="50em">
      <TabList selectedTabKey={tabKey} tabs={tabs} onTabSelect={() => { }} />
      <ErrorDisplayListAutoScrolling errors={errors} />
      <PureGU1Field>
        <LabeledTextField label="Current Password" type="Password" required autoFocus value={state.currentPassword} onChange={this.onCurrentPasswordChanged} errorMessage={errors.getErrorMessage("CurrentPassword")} />
      </PureGU1Field>
      <p className='l-box-v heading-text-style'>{passwordPolicyRules}</p>
      <PureGU1Field>
        <LabeledTextField label="New Password" type="Password" required value={state.newPassword} onChange={this.onNewPasswordChanged} errorMessage={errors.getErrorMessage("NewPassword")} />
      </PureGU1Field>
      <PureGU1Field>
        <LabeledTextField label="Confirm New Password" type="Password" required value={state.confirmNewPassword} onChange={this.onConfirmNewPasswordChanged} errorMessage={errors.getErrorMessage("ConfirmNewPassword")} />
      </PureGU1Field>
      <div className="l-box-vt">
        <div className="action-buttons">
          <button className="pure-button pure-button-transparent" onClick={this.props.closeDialog}>Cancel</button>
          <button className="pure-button pure-button-primary" onClick={this.onChangePasswordClicked}>Change Password</button>
        </div>
      </div>
    </Dialog>;
  }

  private onCurrentPasswordChanged = (currentPassword: string) => {
    this.setState({currentPassword});
  }

  private onNewPasswordChanged = (newPassword: string) => {
    this.setState({newPassword});
  }

  private onConfirmNewPasswordChanged = (confirmNewPassword: string) => {
    this.setState({confirmNewPassword});
  }

  private onChangePasswordClicked = () => {
    this.props.accountsApiClient.changePassword({
      currentPassword: this.state.currentPassword,
      newPassword: this.state.newPassword,
      confirmNewPassword: this.state.confirmNewPassword
    }).then(
      response => {
        if (response.success) {
          this.props.closeDialog();
          this.props.flashMessageCallback("Password changed.");
        }
        else {
          this.setState({
            errors: new ErrorList(response.errors)
          });
        }
      }
    );
  }
}