import { Layout } from "./layout";
import { DashboardApiUrls } from "Components/Home/dashboardApiClient";

export interface AuthenticatedUserLayout extends Layout {
  loggedInUser: LoggedInUser;
  selectedClaimOrApplicationKey: string | null;
  dashboardApiUrls: DashboardApiUrls;
  advocateAttorneyAddClaimPageUrl: string | null;
}

export interface LoggedInUser {
  userId: number;
  informalDisplayName: string;
  formalDisplayName: string;
  phoneNumber: string;
  email: string;
  isVictimClaimant: boolean;
  victimClaimantClaimOrApplications: ClaimOrApplicationNumberAndId[];
}

export type ClaimOrApplication = "Claim" | "Application";

export interface ClaimOrApplicationNumberAndId {
  claimId: number | null;
  claimApplicationId: number;
  applicationNumber: string;
  claimNumber: string | null;
  type: ClaimOrApplication;
  key: string;
  url: string;
}

export function isAuthenticatedUserLayout(layout: Layout | AuthenticatedUserLayout): layout is AuthenticatedUserLayout {
  return !!(layout as AuthenticatedUserLayout).loggedInUser;
}
