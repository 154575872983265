import { IFetcher } from "spry-react/utils/IFetcher";
import { PhoneNumber } from "spry-react/Phone/phoneField";
import { SimpleCode, KeyedCode } from "spry-react/utils/apiTypes";
import { ApiEditResponse } from "shared/DTOs/ApiEditResponse";
import { ApiValidationResponse } from "shared/DTOs/ApiValidationResponse";
import { Address } from "spry-react/Address/addressField";
import { SuggestionState } from "spry-react/Address/addressSuggestion";
import { FetchWithSerialOperationHandling } from "spry-react/utils/fetchWithSerialOperationHandling";
import { Layout } from "Components/Shared/Layout/layout";

export interface AccountsApiClientUrls {
  signInUrl: string;
  signOutUrl: string;
  createNewUserUrl: string;
  confirmCreateCodeUrl: string;
  resendCreateCodeUrl: string;
  accountSetupUrl: string;
  getChangePasswordInfoUrl: string;
  changePasswordUrl: string;
  forgotPasswordEmailUrl: string;
  confirmPasswordResetCodeUrl: string;
  resendPasswordResetCodeUrl: string;
  resetPasswordUrl: string;
  getEditUserAccountInfoUrl: string;
  editUserAccountInfoUrl: string;
}

export interface SignInRequest {
  email: string;
  password: string;
  returnUrl: string;
  recaptchaResponse: string;
}

export interface SignInResponse extends ApiEditResponse {
  requiresCaptcha: boolean;
  redirectUrl: string;
}

export interface SignInPageResponse {
  accountsApiClientUrls: AccountsApiClientUrls;
  createNewUserPageUrl: string;
  forgotPasswordPageUrl: string;
  forgotUsernamePageUrl: string;
  recaptchaPublicSiteKey: string;
  returnUrl: string;
  layout: Layout;
}

export interface SignOutResponse {
  siteRootUrl: string;
}

export interface CreateNewUserPageResponse {
  accountsApiClientUrls: AccountsApiClientUrls;
  signInPageUrl: string;
  addressSuggestionUrl: string;
  states: KeyedCode[];
  recaptchaPublicSiteKey: string;
  passwordPolicyRules: string[];
  layout: Layout;
}

export interface CreateNewUserRequest {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phoneNumber: PhoneNumber;
  recaptchaResponse: string;
  userType: PortalUserType;
  assistingOrganization: string; // adv only
  address: Address; // both adv/attorney
  firmName: string;  // atty only
  fax: PhoneNumber;  // atty only
  taxId: string;  // atty only
}

export interface AddressSuggestionState {
  cities: string[];
  states: { state: string; stateName: string; }[];
  lastDefaults: SuggestionState;
}

export type PortalUserType = 'VictimClaimant' | 'Advocate' | 'Attorney';

export interface CreateNewUserResponse extends ApiEditResponse {
  email: string;
  expiresOn: string;
}

export interface ConfirmCreateCodeRequest {
  email: string;
  code: string;
}

export interface ResendCreateCodeResponse {
  didSend: boolean;
}

export interface AccountSetupRequest {
  email: string;
  code: string;
  password: string;
  confirmPassword: string;
}

export interface AccountSetupResponse extends ApiValidationResponse {
  accountCreatedRedirectUrl: string;
}

export interface GetChangePasswordInfoResponse {
  passwordPolicyRules: string[];
}

export interface ChangePasswordRequest  {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export interface ForgotPasswordEmailRequest {
  email: string;
  recaptchaResponse: string;
}

export interface ForgotPasswordPageResponse {
  defaultEmail: string | null;
  accountsApiClientUrls: AccountsApiClientUrls;
  signInPageUrl: string;
  recaptchaPublicSiteKey: string;
  passwordPolicyRules: string[];
  layout: Layout;
}

export interface ForgotPasswordEmailResponse extends ApiValidationResponse {
  userMatchWasFound: boolean;
  portalUserId: number | null;
  email: string;
  expiresOn: string;
}

export interface ConfirmPasswordResetCodeRequest {
  portalUserId: number;
  code: string;
}

export interface ResendPasswordResetCodeRequest {
  portalUserId: number;
}

export interface ResendPasswordResetCodeResponse {
  didSend: boolean;
}

export interface ResetPasswordRequest {
  portalUserId: number;
  code: string;
  password: string;
  confirmPassword: string;
}

export interface ResetPasswordResponse extends ApiValidationResponse {
  redirectUrl: string;
}

export interface GetUpdateProfileResponse {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: PhoneNumber;
  userType: PortalUserType;
  assistingOrganization: string;
  address: Address;
  firmName: string;
  fax: PhoneNumber;
  taxId: string;
  receivePortalTaskNotificationDigest: boolean;
  namePrefixes: SimpleCode[];
  nameSuffixes: SimpleCode[];
  states: KeyedCode[];
  addressSuggestionUrl: string;
}

export interface UpdateProfileRequest {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: PhoneNumber;
  userType: PortalUserType;
  assistingOrganization: string;
  address: Address;
  firmName: string;
  fax: PhoneNumber;
  taxId: string;
  receivePortalTaskNotificationDigest: boolean;
}

export class AccountsApiClient {
  private readonly signInUrl: string;
  private readonly signOutUrl: string;
  private readonly createNewUserUrl: string;
  private readonly confirmCreateCodeUrl: string;
  private readonly resendCreateCodeUrl: string;
  private readonly accountSetupUrl: string;
  private readonly getChangePasswordInfoUrl: string;
  private readonly changePasswordUrl: string;
  private readonly forgotPasswordEmailUrl: string;
  private readonly confirmPasswordResetCodeUrl: string;
  private readonly resendPasswordResetCodeUrl: string;
  private readonly resetPasswordUrl: string;
  private readonly getUpdateProfileUrl: string;
  private readonly updateProfileUrl: string;

  private readonly fetcher: FetchWithSerialOperationHandling;

  constructor(accountsApiClientUrls: AccountsApiClientUrls, fetcher: FetchWithSerialOperationHandling) {
    this.signInUrl = accountsApiClientUrls.signInUrl;
    this.signOutUrl = accountsApiClientUrls.signOutUrl;
    this.createNewUserUrl = accountsApiClientUrls.createNewUserUrl;
    this.confirmCreateCodeUrl = accountsApiClientUrls.confirmCreateCodeUrl;
    this.resendCreateCodeUrl = accountsApiClientUrls.resendCreateCodeUrl;
    this.accountSetupUrl = accountsApiClientUrls.accountSetupUrl;
    this.getChangePasswordInfoUrl = accountsApiClientUrls.getChangePasswordInfoUrl;
    this.changePasswordUrl = accountsApiClientUrls.changePasswordUrl;
    this.forgotPasswordEmailUrl = accountsApiClientUrls.forgotPasswordEmailUrl;
    this.confirmPasswordResetCodeUrl = accountsApiClientUrls.confirmPasswordResetCodeUrl;
    this.resendPasswordResetCodeUrl = accountsApiClientUrls.resendPasswordResetCodeUrl;
    this.resetPasswordUrl = accountsApiClientUrls.resetPasswordUrl;
    this.getUpdateProfileUrl = accountsApiClientUrls.getEditUserAccountInfoUrl;
    this.updateProfileUrl = accountsApiClientUrls.editUserAccountInfoUrl;

    this.fetcher = fetcher;
  }

  public signIn(data: SignInRequest): Promise<SignInResponse> {
    return this.fetcher.postJson(this.signInUrl, data);
  }

  public signOut(): Promise<SignOutResponse> {
    return this.fetcher.postJson(this.signOutUrl, null);
  }

  public createNewUser(data: CreateNewUserRequest) : Promise<CreateNewUserResponse> {
    return this.fetcher.postJson(this.createNewUserUrl, data);
  }

  public confirmCreateCode(data: ConfirmCreateCodeRequest) : Promise<ApiValidationResponse> {
    return this.fetcher.postJson(this.confirmCreateCodeUrl, data);
  }

  public resendCreateCode(email: string) : Promise<ResendCreateCodeResponse> {
    return this.fetcher.postJson(this.resendCreateCodeUrl, { email });
  }

  public accountSetup(data: AccountSetupRequest) : Promise<AccountSetupResponse> {
    return this.fetcher.postJson(this.accountSetupUrl, data);
  }

  public getChangePasswordInfo() : Promise<GetChangePasswordInfoResponse> {
    return this.fetcher.getJson(this.getChangePasswordInfoUrl, undefined);
  }

  public changePassword(data: ChangePasswordRequest) : Promise<ApiEditResponse> {
    return this.fetcher.postJson(this.changePasswordUrl, data);
  }

  public forgotPasswordEmail(data: ForgotPasswordEmailRequest) : Promise<ForgotPasswordEmailResponse> {
    return this.fetcher.postJson(this.forgotPasswordEmailUrl, data);
  }

  public confirmPasswordResetCode(data: ConfirmPasswordResetCodeRequest) : Promise<ApiValidationResponse> {
    return this.fetcher.postJson(this.confirmPasswordResetCodeUrl, data);
  }

  public resendPasswordResetCode(data: ResendPasswordResetCodeRequest) : Promise<ResendPasswordResetCodeResponse> {
    return this.fetcher.postJson(this.resendPasswordResetCodeUrl, data);
  }

  public resetPassword(data: ResetPasswordRequest) : Promise<ResetPasswordResponse> {
    return this.fetcher.postJson(this.resetPasswordUrl, data);
  }

  public getUpdateProfile(): Promise<GetUpdateProfileResponse> {
    return this.fetcher.getJson(this.getUpdateProfileUrl);
  }

  public updateProfile(data: UpdateProfileRequest): Promise<ApiValidationResponse> {
    return this.fetcher.postJson(this.updateProfileUrl, data);
  }
}