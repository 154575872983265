import * as React from "react";
import * as ReactDOM from "react-dom";
import ReCAPTCHA from 'react-google-recaptcha';
import { AccountsApiClient, SignInPageResponse } from "./accountsApiClient";
import { ErrorList } from 'spry-react/Errors/errorList';
import { ErrorDisplayListAutoScrolling } from 'spry-react/Errors/errorDisplayListAutoScrolling';
import { LabeledTextField } from 'spry-react/Text/textField';
import { CheckboxField } from 'spry-react/Checkbox/checkboxField';
import { onLoad } from "spry-react/utils/onLoad";
import PortalFullPage from "Components/Shared/advocatePortalFullPage";
import { AdvocatePortalApp } from "Components/Shared/advocatePortalApp";
import { IAppContext } from 'Components/Shared/appContext';
import { Link } from "spry-react/Link/link";

import './signin.less';
import { Layout } from "Components/Shared/Layout/layout";
import { ContentContainer } from "spry-react/Page/contentContainer";

export interface Props {
  context: IAppContext;
  layout: Layout;
  accountsApiClient: AccountsApiClient;
  recaptchaPublicSiteKey: string;
  createNewUserPageUrl: string;
  forgotPasswordPageUrl: string;
  returnUrl: string;
}

export interface State {
  email: string;
  password: string;
  recaptchaResponse?: string;
  requiresCaptcha: boolean;
  errors: ErrorList;
  showPassword: boolean;
}

export class SignInPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      requiresCaptcha: false,
      errors: new ErrorList([]),
      showPassword: false
    };
  }

  render() {
    const props = this.props;
    const state = this.state;
    const headerContent = <h1>Crime Victims Compensation Login</h1>;
    const forgotPasswordPageUrl = this.props.forgotPasswordPageUrl + (state.email.length > 0 ? `?email=${state.email}` : "");

    return <PortalFullPage
      title='Sign In'
      headerContent={headerContent}
      borderedHeader={true}
      appContext={props.context}
      layout={props.layout}>
        <ContentContainer>
          <div className="signin-content">
            <form className="signin-panel" onSubmit={this.onSignInClicked} data-allow-enter>
              <ErrorDisplayListAutoScrolling errors={state.errors} />
              <div className="field">
                <LabeledTextField label="Email" value={state.email} onChange={this.onEmailChanged} autoFocus />
              </div>
              <div className="field" style={{paddingBottom: ".5rem"}}>
                <label htmlFor='password'>Password</label>
                <input id='password' type={state.showPassword ? "text" : "password"} value={state.password} onChange={e => { this.onPasswordChanged(e.currentTarget.value); }} />
              </div>
              <div className="pure-g">
                <div className="pure-u-1 field field-h" style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <div>
                    <label className="label l-box-hr-1-2x" htmlFor="show-password" style={{display:"inline"}}>Show Password</label>
                    <CheckboxField
                      id="show-password"
                      value={state.showPassword}
                      onToggle={() => this.setState(prev => ({...prev, showPassword: !prev.showPassword}))} />
                  </div>
                </div>
              </div>
              {state.requiresCaptcha &&
                <div className='l-box-vb'>
                  <ReCAPTCHA ref="recaptcha" sitekey={props.recaptchaPublicSiteKey} onChange={this.onRecaptchaChanged} />
                </div>
              }
              <button type="submit" className="signin-login-button pure-button pure-button-primary">Log In</button>
              <div className="signin-options">
                <Link url={forgotPasswordPageUrl}>Forgot your password?</Link>
              </div>
            </form>

            <div className="signin-newuser">
              <span>New user?</span>
              <Link className='l-box-hl-1-2x' onClick={this.onCreateNewUserClicked}>Register here.</Link>
            </div>

            <div className="signin-publicrecordsstatement">
              <p>Information submitted to the Ohio Attorney General’s Office, including transcripts of electronic discussions, may be disclosed pursuant to a public records request.</p>
              <br />
              <p>More information about public records can be found in Ohio Rev. Code § 149.43, and at <a href="https://www.ohioattorneygeneral.gov/About-AG/Public-Records-Access">https://www.ohioattorneygeneral.gov/About-AG/Public-Records-Access</a>.</p>
            </div>
            {
             this.props.layout.accessibilityNoticeUrl && 
              <div className="signin-accessibility-notice">
                <Link url={this.props.layout.accessibilityNoticeUrl}>Notice under the Americans with Disabilities Act and Rehabilitation Act</Link>
              </div>
            }
          </div>
        </ContentContainer>
    </PortalFullPage>;
  }

  private onEmailChanged = (email: string) => this.setState({email});

  private onPasswordChanged = (password: string) => this.setState({password});

  private onRecaptchaChanged = (value: string) => {
    this.setState({recaptchaResponse: value});
  }

  private onSignInClicked = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    var request = {
      email: this.state.email,
      password: this.state.password,
      recaptchaResponse: this.state.recaptchaResponse,
      returnUrl: this.props.returnUrl
    };
    this.props.accountsApiClient.signIn(request)
      .catch()
      .then(
        response => {
          if (response.success) {
            this.props.context.setWindowLocationHref(response.redirectUrl);
          }
          else {
            this.setState({
              requiresCaptcha: response.requiresCaptcha,
              errors: new ErrorList(response.errors || []),
              password: ""
            });
          }
        });
  }

  private onCreateNewUserClicked = () => {
    this.props.context.setWindowLocationHref(this.props.createNewUserPageUrl);
  }
}

onLoad((data: SignInPageResponse) => {
  ReactDOM.render(<AdvocatePortalApp csrfInfo={data.layout.csrfInfo} renderChildren={context => {
    const accountsApiClient = new AccountsApiClient(data.accountsApiClientUrls, context.serialFetcher);
    return <SignInPage
      context={context}
      layout={data.layout}
      accountsApiClient={accountsApiClient}
      recaptchaPublicSiteKey={data.recaptchaPublicSiteKey}
      createNewUserPageUrl={data.createNewUserPageUrl}
      forgotPasswordPageUrl={data.forgotPasswordPageUrl}
      returnUrl={data.returnUrl} />;
  }} />, document.getElementById("root"));
});