import * as React from "react";
import { Dialog } from "spry-react/Dialog/dialog";
import { PureGU1Field } from "./pureG";
import { Button } from "spry-react/Buttons/button";

export interface Props {
  officeTollFreePhoneNumber: string;
  organizationPhoneNumber: string;
  closeDialog: () => void;
}

export class HelpDialog extends React.Component<Props> {
  render() {
    return (
      <Dialog singleTabText='Help' width='80rem'>
        <PureGU1Field>
          <h4>Why should I apply?</h4>
          If you were the victim of a violent crime and you have related expenses, we may be able to help.
          We pay for a wide range of expenses such as medical or counseling bills, work loss, and funeral expenses.
          We generally can't pay for property expenses.
        </PureGU1Field>
        <PureGU1Field>
          <h4>If you have previously applied:</h4>
          If you have previously applied for compensation and are filing for additional compensation as a result of the same incident, please provide your claim number on the first page.
        </PureGU1Field>
        <PureGU1Field>
          <h4>What will I need?</h4>
          <ol>
            <li>
              Want help applying? 
              If you want help with this form you should contact us, a local victim advocate, or an attorney (If you decide to have an attorney assist you with filing an application, that attorney cannot charge you. The attorney must send their bill to Crime Victim Services for consideration.).
              The more information you provide, the faster we can process your claim.
            </li>
            <li>
              Information about you. You will need personal information for the victim (such as name, Social Security Number, date of birth, and address). 
              If you're not the victim, you're a "claimant," and you'll need to provide information for both you and the victim.
            </li>
            <li>
              Information about the crime. You should also try to gather information about the crime (date it occurred, law enforcement agency you reported to, criminal offense that was committed).
            </li>
            <li>
              Expenses. You'll want to get as much information about your expenses as possible. By law, we need to verify any claim you file, so we'll probably be requesting additional documentation from you.
            </li>
          </ol>
        </PureGU1Field>
        <PureGU1Field>
          <h4>When should I apply?</h4>
          You can apply whenever you want, so apply when you feel comfortable and ready to begin the process. If you don't have all the information in these forms, you can still apply and we can help you work through the process.
          If you have expenses later (such as unexpected medical complications), you can apply again anytime within 6 years of the last time we granted or denied any of your applications.
        </PureGU1Field>
        <PureGU1Field>
          <h4>Do I need to complete this whole form?</h4>
          No.
        </PureGU1Field>
        <PureGU1Field>
          <h4>Can I fill out one application for multiple victims?</h4>
          No. You must file a seperate claim for each victim.
        </PureGU1Field>
        <PureGU1Field>
          <h4>Emergency Award:</h4>
          After an application has been filed, if a final award is likely, the law may provide for payment of an emergency award of up to $2,000 to qualified victims/claimants who, because of the crime, will suffer undue hardship without immediate economic relief.
        </PureGU1Field>
        <PureGU1Field>
          <h2 className='align-center'>Need help? Call {this.props.organizationPhoneNumber} or {this.props.officeTollFreePhoneNumber}</h2>
        </PureGU1Field>
        <div className='pure-g'>
          <div className="pure-u-1">
            <div className="action-buttons">
              <Button color="primary" onClick={this.props.closeDialog}>Close</Button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }

}