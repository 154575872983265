import * as _ from 'underscore';
import { SimpleCode, KeyedCode } from 'spry-react/utils/apiTypes';
import { FetchWithStandardErrorHandling } from 'spry-react/utils/fetchWithStandardErrorHandling';
import { ConcurrentPromises } from 'spry-react/utils/concurrentPromises';
import { AuthenticatedUserLayout } from 'Components/Shared/Layout/authenticatedUserLayout';
import { FetchWithSerialOperationHandling } from 'spry-react/utils/fetchWithSerialOperationHandling';
import { ApiEditResponse } from 'shared/DTOs/ApiEditResponse';
import { IAppContext } from 'Components/Shared/appContext';
import { ClaimsApiUrls } from 'Components/Claims/claimsApiClient';
import { SortColumn } from './advocateAttorneyIndex';

export interface VictimClaimantDashboardResponse {
  layout: AuthenticatedUserLayout;
}

export interface ClaimApplicationDetailsPageResponse {
  layout: AuthenticatedUserLayout;
  claimApplication: ClaimApplicationDetailsResult;
  medicalRecordAuthorizationMailingAddress: string;
}

export interface ClaimDetailsPageResponse {
  layout: AuthenticatedUserLayout;
  claim: ClaimDetailsResult;
  claimsApiUrls: ClaimsApiUrls;
}

export interface ClaimApplicationDetailsResult {
  key: string;
  claimApplicationId: number;
  claimApplicationNumber: string;
  victimName: string;
  incidentDate: string;
  isSubmitted: boolean;
  applicationPdfStatus?: ClaimApplicationFileStatus;
  editUrl: string;
  downloadApplicationPdfUrl: string;
  regenerateClaimApplicationPdfUrl: string;
  createPortalMessageUrl: string;
  refreshUrl: string;
  cancelUrl: string;
}

export interface ClaimDetailsResult {
  key: string;
  userHasAccessToClaim: boolean;
  linkedApplicationNumbers: string[];
  matterId: number;
  claimId: number;
  claimNumber: string;
  filedDate: string;
  dueDate: string;
  claimants: string[];
  claimType: string;
  compensationRequested: string[];
  status: string;
  createdOn: string;
  victimName: string;
  incidentDate: string;
  documents: PortalUserUploadedDocument[];
  outstandingPortalTasks: PortalTask[];
  completedPortalTasks: PortalTask[];
  claimProcessingIsComplete: boolean;
  claimProcessingCompletedOn: string;
  findingOfFactAndDecisionIsComplete: boolean;
  findingOfFactAndDecisionCompletedOn: string;
}

export interface PortalUserUploadedDocument {
  documentId: number;
  fileName: string;
  uploadedOn: string;
  downloadDocumentUrl: string;
}

export interface PortalTask {
  portalTaskId: number;
  name: string;
  description: string;
  completedOn: string;
  isComplete: boolean;
  markedCompleteByUser: string;
  assignedOn: string;
  assignedByUser: string;
  discussionCommentCount: number | null;
  discussionId: number;
}

export type ClaimApplicationFileStatus = "Generating" | "Success" | "Failed";

export interface DashboardResponse {
  layout: AuthenticatedUserLayout;
  claimResults: ClaimsResult[];
  unfilteredClaimCount: number;
  filter: ClaimsFilter;
  filterOptions: ClaimsFilterOptions;
  pageSettings: any;
  resultsMessage: string;
}

export interface AdvocateAttorneyAddClaimPageResponse {
  layout: AuthenticatedUserLayout;
}

export interface ClaimsResult {
  claimId: number | null;
  claimApplicationId: number | null;
  claimOrApplicationNumber: string;
  victimName: string;
  incidentDate: string;
  outstandingTasks: number;
  claimStatus: string;
  isSubmitted: boolean;
  editUrl: string;
  detailsUrl: string;
}

export interface ClaimsFilter {
  sortBy: SortColumn;
  sortDirection: string;
  isFiltered: boolean;
  page: number;
  filterKeyword?: string;
  statuses: string[]
}

export interface ClaimsFilterOptions {
  statuses: KeyedCode[];
}


export interface AboutResponse {
  applicationNamePrefix: string;
  applicationName: string;
  versionNumber: string;
  currentYear: string;

  endUserLicenseAgreementUrl: string;
  softwareLicenseDisclosuresUrl: string;
}

interface CreateClaimApplicationResponse extends ApiEditResponse {
  redirectUrl: string;
}

export interface CreateAccessRequestRequest {
  portalUserId: number;
  claimNumber: string;
  victimName: string;
  representingClaimantName: string;
  recaptchaResponse: string;
}

export interface DashboardApiUrls {
  createClaimApplicationUrl: string;
  createSupplementalClaimApplicationUrl: string;
  getAdvocateAttorneyClaimsUrl: string;
  createAccessRequestUrl: string;
}

export class DashboardApiClient {
  private urls: DashboardApiUrls;
  private serialFetcher: FetchWithSerialOperationHandling;
  private fetcher: FetchWithStandardErrorHandling;

  constructor(urls: DashboardApiUrls, serialFetcher: FetchWithSerialOperationHandling, fetcher: FetchWithStandardErrorHandling) {
    this.urls = urls;
    this.serialFetcher = serialFetcher;
    this.fetcher = fetcher;
  }
  
  newClaimApplication(): Promise<CreateClaimApplicationResponse> {
    return this.serialFetcher.postJson(this.urls.createClaimApplicationUrl, {});
  }

  newSupplementalClaimApplication(matterId: number): Promise<CreateClaimApplicationResponse> {
    return this.serialFetcher.postJson(this.urls.createSupplementalClaimApplicationUrl, { matterId });
  }

  filterClaims(filter: ClaimsFilter, concurrencyContext?: ConcurrentPromises): Promise<DashboardResponse> {
    return this.fetcher.postJson(this.urls.getAdvocateAttorneyClaimsUrl, filter, concurrencyContext);
  }

  createNewClaimApplicationAndRedirect(appContext: IAppContext){
    this.newClaimApplication().then(
      response => {
        if (response.success) {
          appContext.setWindowLocationHref(response.redirectUrl);
        }
        else {
          appContext.flashMessage('Failed to create claim application.');
        }
      }
    )
  }

  createNewSupplementalClaimApplicationAndRedirect(matterId: number, appContext: IAppContext){
    this.newSupplementalClaimApplication(matterId).then(
      response => {
        if (response.success) {
          appContext.setWindowLocationHref(response.redirectUrl);
        }
        else {
          appContext.flashMessage('Failed to create claim application.');
        }
      }
    )
  }

  createAccessRequest(request: CreateAccessRequestRequest): Promise<ApiEditResponse> {
    return this.serialFetcher.postJson(this.urls.createAccessRequestUrl, request);
  }
}