import * as React from "react";
import { ContextMenu, ContextMenuItem } from "spry-react/ContextMenu/contextMenu";
import { ZipCodesApiClient } from "spry-react/Address/zipCodesApiClient";
import { UpdateProfileDialog } from "Components/Accounts/updateProfileDialog";
import { AccountsApiClient } from "Components/Accounts/accountsApiClient";
import { ChangePasswordDialog } from "Components/Accounts/changePasswordDialog";
import { IAppContext } from "../appContext";

export function UserMenu(props: { 
  appContext: IAppContext,
  accountsApiClient: AccountsApiClient 
}){
  const {appContext, accountsApiClient} = props;
  return <>
    <ContextMenu>
      <ContextMenuItem iconClass="users-icon" text="Update Profile" onClick={() => onUpdateProfileClicked(appContext, accountsApiClient)} />
      <ContextMenuItem iconClass="password-icon" text="Change Password" onClick={() => onChangePasswordClicked(appContext, accountsApiClient)} />
      <ContextMenuItem iconClass="logout-icon" text="Logout" onClick={() => onLogoutClicked(appContext, accountsApiClient)} />
    </ContextMenu>
  </>;
}


function onChangePasswordClicked(appContext: IAppContext, accountsApiClient: AccountsApiClient) {
  accountsApiClient.getChangePasswordInfo().then(
    response => {
      appContext.openDialog(closeDialog => {
        return <ChangePasswordDialog
          closeDialog={closeDialog}
          passwordPolicyRules={response.passwordPolicyRules}
          accountsApiClient={accountsApiClient}
          flashMessageCallback={appContext.flashMessage} />;
      });
    }
  );
}

function onLogoutClicked(appContext: IAppContext, accountsApiClient: AccountsApiClient) {
  accountsApiClient.signOut().then(
    (response) => {
       appContext.setWindowLocationHref(response.siteRootUrl);
    }
  );
}

function onUpdateProfileClicked(appContext: IAppContext, accountsApiClient: AccountsApiClient) {
  accountsApiClient.getUpdateProfile().then(
    response => {
      appContext.openDialog(closeDialog => {
        return <UpdateProfileDialog
          closeDialog={closeDialog}
          accountsApiClient={accountsApiClient}
          context={appContext}
          initialData={{
            firstName: response.firstName,
            middleName: response.middleName,
            lastName: response.lastName,
            email: response.email,
            phone: response.phone,
            userType: response.userType,
            assistingOrganization: response.assistingOrganization,
            address: response.address,
            firmName: response.firmName,
            fax: response.fax,
            taxId: response.taxId,
            receivePortalTaskNotificationDigest: response.receivePortalTaskNotificationDigest
          }}
          states={response.states}
          zipCodesApiClient={new ZipCodesApiClient(response.addressSuggestionUrl)}
        />;
      });
    }
  );
}
