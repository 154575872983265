import * as React from "react";
import { Page } from "spry-react/Page/page";
import { IAppContext } from "Components/Shared/appContext";
import { PortalFullHeader } from "./portalFullHeader";
import { AccountsApiClientUrls, AccountsApiClient } from "Components/Accounts/accountsApiClient";
import { PortalSiteNavigation } from "./portalSiteNavigation";
import { AuthenticatedUserLayout, isAuthenticatedUserLayout } from 'Components/Shared/Layout/authenticatedUserLayout';
import { Layout } from "./Layout/layout";
import { DashboardApiClient } from "Components/Home/dashboardApiClient";

interface Props {
  appContext: IAppContext;
  title?: string;
  headerContent?: React.ReactNode;
  children?: React.ReactNode;
  borderedHeader?: boolean;
  layout: Layout | AuthenticatedUserLayout
}

export class PortalPage extends React.Component<Props> {

  render() {
    const title = this.props.title ? `${this.props.title} - MatrixAdvocate Portal` : "MatrixAdvocate Portal";
    return <Page
      header={<AdvocateHeader
        appContext={this.props.appContext}
        layout={this.props.layout}
        title={this.props.title}
        headerContent={this.props.headerContent}
        borderedHeader={this.props.borderedHeader}
        selectedClaimOrApplicationKey={isAuthenticatedUserLayout(this.props.layout) ? this.props.layout.selectedClaimOrApplicationKey : null}
         />}
      title={title}
      children={this.props.children}
    />;
  }
}

export interface AdvocateHeaderProps {
  appContext: IAppContext;
  layout: Layout | AuthenticatedUserLayout;
  title?: string;
  headerContent?: React.ReactNode;
  borderedHeader?: boolean;
  selectedClaimOrApplicationKey: string | null;
}
export class AdvocateHeader extends React.PureComponent<AdvocateHeaderProps> {
  render() {
    const { appContext, layout, selectedClaimOrApplicationKey } = this.props;

    const navContent = <PortalSiteNavigation
      appContext={appContext}
      user={isAuthenticatedUserLayout(layout) ? layout.loggedInUser : null}
      selectedClaimOrApplicationKey={selectedClaimOrApplicationKey}
      dashboardApiClient={isAuthenticatedUserLayout(layout) ? new DashboardApiClient(layout.dashboardApiUrls, appContext.serialFetcher, appContext.fetcher) : null}
      accountsApiClient={new AccountsApiClient(layout.accountsApiClientUrls, appContext.serialFetcher)}
      recaptchaPublicSiteKey={layout.recaptchaPublicSiteKey}
      officeTollFreePhoneNumber={layout.officeTollFreePhoneNumber}
      organizationPhoneNumber={layout.organizationPhoneNumber}
    />;

    return <>
      <style dangerouslySetInnerHTML={{
        __html: `
        .loading-banner { z-index: 99; }
      `}} />
      <div id="loading-banner"></div>
      <PortalFullHeader
        bordered={this.props.borderedHeader}
        homeUrl={layout.homeUrl}
        homeLinkText={layout.homeLinkText}
        navContent={navContent}
        logoImageUrl={layout.logoImageUrl}
        children={this.props.headerContent} />
    </>;
  }
}
