import * as React from "react";
import { FullPage} from "spry-react/Page/fullPage";
import { IAppContext } from "Components/Shared/appContext";
import { AdvocateHeader } from "./advocatePortalPage";
import { isAuthenticatedUserLayout, AuthenticatedUserLayout } from "./Layout/authenticatedUserLayout";
import { Layout } from "./Layout/layout";

interface Props {
  appContext: IAppContext;
  title?: string;
  headerContent?: React.ReactNode;
  children?: React.ReactNode;
  borderedHeader?: boolean;
  layout: Layout | AuthenticatedUserLayout;
}

export default class PortalFullPage extends React.Component<Props> {
  render() {
    const { title } = this.props;
    const pageTitle = title ? `${title} - MatrixAdvocate Portal` : "MatrixAdvocate Portal";

    return <FullPage
      title={pageTitle}
      header={
        <AdvocateHeader
          appContext={this.props.appContext}
          title={this.props.title}
          headerContent={this.props.headerContent}
          borderedHeader={this.props.borderedHeader}
          layout={this.props.layout}
          selectedClaimOrApplicationKey={isAuthenticatedUserLayout(this.props.layout) ? this.props.layout.selectedClaimOrApplicationKey : null}
         />
      }
      children={this.props.children} />;
  }
}
