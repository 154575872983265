import * as React from "react";

interface Props {
}

interface State {
  error: any;
  errorInfo: any;
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null
    };
  }

  render() {
    if (this.state.errorInfo) {
      return <>
        <h2>An unexpected error has occurred</h2>
        The details of the error have been logged. Try refreshing the page and trying the operation again.  If the problem persists please contact technical support.
      </>;
    }

    return this.props.children;
  }

  componentDidCatch?(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ error, errorInfo });

    /* todo: log error to server */
  }
}