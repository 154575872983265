import { IAppContext } from "../appContext";
import { ContextMenu, ContextMenuItem, NavigateContextMenuItem } from "spry-react/ContextMenu/contextMenu";
import * as React from "react";
import { ClaimOrApplicationNumberAndId, LoggedInUser } from "../Layout/authenticatedUserLayout";
import { DashboardApiClient } from "Components/Home/dashboardApiClient";
import { RequestClaimAccessDialog } from "Components/Home/requestClaimAccess";

export function ClaimMenu(props: {
  appContext: IAppContext;
  dashboardApiClient: DashboardApiClient;
  claimOrApplicationNumberAndIds: ClaimOrApplicationNumberAndId[],
  portalUser: LoggedInUser;
  recaptchaPublicSiteKey: string;
}){
  const { appContext, claimOrApplicationNumberAndIds, dashboardApiClient, portalUser, recaptchaPublicSiteKey } = props;

  return <>
  <ContextMenu>
    {claimOrApplicationNumberAndIds.map(c => 
      <NavigateContextMenuItem text={c.claimNumber || c.applicationNumber} url={c.url} iconClass="case-icon" />
    )}
    <ContextMenuItem text={"Create new application"}
      iconClass='add-icon' 
      onClick={() => { dashboardApiClient.createNewClaimApplicationAndRedirect(appContext) }
      } />
    <ContextMenuItem text={"Request access to claim"}
      iconClass='add-icon' 
      onClick={() => { 
        appContext.openDialog((closeDialog) => {
          return <RequestClaimAccessDialog 
            closeDialog={closeDialog}
            appContext={appContext}
            apiClient={dashboardApiClient}
            portalUser={portalUser}
            recaptchaPublicSiteKey={recaptchaPublicSiteKey}
            claimNumberInputMask="V99-99999"
          />;
        })
      }} />
  </ContextMenu>
  </>;
}
