import { ErrorList } from "spry-react/Errors/errorList";
import { ApiEditResponse } from "shared/DTOs/ApiEditResponse";

export function setErrorsFromResponse(setErrors: (errorList: ErrorList) => void, response: ApiEditResponse): boolean {
  if(!response.errors?.length){
    setErrors(new ErrorList([]));
    return false;
  }
  
  setErrors(new ErrorList(response.errors));
  return true;
}