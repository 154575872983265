import * as React from "react";
import { SiteNavigationLink } from "spry-react/Page/siteNavigation";
import { HelpDialog } from "./helpDialog";
import { IAppContext } from "Components/Shared/appContext";
import { Link } from "spry-react/Link/link";
import { OpenContextMenuCallback } from "spry-react/App/app";
import { ContextMenu, ContextMenuItem, ContextMenuTrigger } from "spry-react/ContextMenu/contextMenu";
import { UpdateProfileDialog } from "Components/Accounts/updateProfileDialog";
import { ChangePasswordDialog } from "Components/Accounts/changePasswordDialog";
import { ZipCodesApiClient } from "spry-react/Address/zipCodesApiClient";
import { AccountsApiClient } from "Components/Accounts/accountsApiClient";
import { UserMenu } from "./SiteNavigation/UserMenu";
import { ClaimMenu } from "./SiteNavigation/ClaimMenu";
import { find } from "spry-react/utils/arrayExtensions";
import { LoggedInUser, ClaimOrApplicationNumberAndId } from "./Layout/authenticatedUserLayout";
import { DashboardApiClient } from "Components/Home/dashboardApiClient";

export interface Props {
  appContext: IAppContext;
  user?: LoggedInUser;
  dashboardApiClient: DashboardApiClient;
  selectedClaimOrApplicationKey: string;
  accountsApiClient: AccountsApiClient;
  recaptchaPublicSiteKey: string;
  officeTollFreePhoneNumber: string;
  organizationPhoneNumber: string;
}

export function PortalSiteNavigation(props: Props) {
  const { user, appContext, accountsApiClient, selectedClaimOrApplicationKey, dashboardApiClient, recaptchaPublicSiteKey, organizationPhoneNumber, officeTollFreePhoneNumber } = props;
  const openHelpDialog = () => {
    const renderDialog = (closeDialog: () => void) => <HelpDialog closeDialog={closeDialog} organizationPhoneNumber={organizationPhoneNumber} officeTollFreePhoneNumber={officeTollFreePhoneNumber} />;
    props.appContext.openDialog(renderDialog);
  };

  return <>
    <SiteNavigationLink 
      title='Help' 
      iconClassName="site-header-button question-circle-icon" 
      text="HELP" 
      onClick={openHelpDialog} />
    { 
      user?.isVictimClaimant && user?.victimClaimantClaimOrApplications?.length > 0 && 
        <ClaimMenuLink 
          appContext={appContext} 
          dashboardApiClient={dashboardApiClient}
          selectedClaimOrApplicationKey={selectedClaimOrApplicationKey}
          claimOrApplicationNumberAndIds={user.victimClaimantClaimOrApplications} 
          portalUser={user}
          recaptchaPublicSiteKey={recaptchaPublicSiteKey}
          /> 
    }
    {
      user &&
       <UserMenuLink 
        appContext={appContext}
        accountsApiClient={accountsApiClient}
        userInformalDisplayName={user.informalDisplayName} />
    }
  </>;
}

function UserMenuLink(props: { 
  appContext: IAppContext,
  accountsApiClient: AccountsApiClient,
  userInformalDisplayName: string,
}){
  const { userInformalDisplayName, appContext, accountsApiClient } = props;
  const openUserMenu = appContext.registerContextMenu(() => 
    <UserMenu appContext={props.appContext} accountsApiClient={accountsApiClient} /> 
  );

  return <> 
    <ContextMenuTrigger openContextMenu={openUserMenu}>
    <a className="site-header-button site-header-flyout-button"
      style={{ width: "auto" }}
      title={userInformalDisplayName}
      aria-label="User">
      {userInformalDisplayName}
    </a>
    </ContextMenuTrigger>
  </>;
}

function ClaimMenuLink(props: {
  appContext: IAppContext,
  selectedClaimOrApplicationKey: string,
  dashboardApiClient: DashboardApiClient;
  claimOrApplicationNumberAndIds: ClaimOrApplicationNumberAndId[],
  portalUser: LoggedInUser,
  recaptchaPublicSiteKey: string
}) {
  const { appContext, claimOrApplicationNumberAndIds, selectedClaimOrApplicationKey, dashboardApiClient, portalUser, recaptchaPublicSiteKey } = props;

  const openMenu = appContext.registerContextMenu(() => 
    <ClaimMenu 
      appContext={appContext}
      dashboardApiClient={dashboardApiClient}
      claimOrApplicationNumberAndIds={claimOrApplicationNumberAndIds}
      portalUser={portalUser}
      recaptchaPublicSiteKey={recaptchaPublicSiteKey}
      /> 
  );

  const selectedItem = find(claimOrApplicationNumberAndIds, c => c.key == selectedClaimOrApplicationKey);
  const selectedText = (selectedItem?.claimNumber ?? selectedItem?.applicationNumber) ?? "Claims";
  return <>
    <ContextMenuTrigger openContextMenu={openMenu}>
      <a className="site-header-button site-header-flyout-button"
        style={{ width: "auto" }}
        title={selectedText}
        aria-label="Claims"
        >
          {selectedText}
      </a>
    </ContextMenuTrigger>
  </>;
}
