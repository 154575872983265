import * as React from "react";
import { App } from "spry-react/App/app";
import { ErrorBoundary } from "./errorBoundary";
import { IAppContext } from "./appContext";
import { RenderNetworkErrorDialog } from "spry-react/Errors/networkErrorDialog";
import { Render500ErrorDialog } from "spry-react/Errors/500ErrorDialog";
import { Render404ErrorDialog } from "spry-react/Errors/404ErrorDialog";
import { Render403ErrorDialog } from "spry-react/Errors/403ErrorDialog";
import { Render401ErrorDialog } from "./401ErrorDialog";
import { FetchWithStandardErrorHandling } from "spry-react/utils/fetchWithStandardErrorHandling";
import { FetchWithSerialOperationHandling } from 'spry-react/utils/fetchWithSerialOperationHandling';

import "./site.less";
import { CrossSiteRequestForgeryTokenInfo } from "spry-react/utils/fetchExtensions";

export interface Props {
  renderChildren: (appContext: IAppContext) => React.ReactElement<any> | React.ReactElement<any>[] | React.ReactPortal | string | number | null | false;
  csrfInfo: CrossSiteRequestForgeryTokenInfo;
}

export function AdvocatePortalApp(props: Props){
  return (
    <ErrorBoundary>
      <App renderChildren={(openDialog, registerContextMenu, registerBeforeUnload, flashMessage, confirm, startSerialOperation, setWindowLocationHref) => {
        const openNetworkErrorDialog = () => { openDialog(RenderNetworkErrorDialog); };
        const open500ErrorDialog = () => { openDialog(Render500ErrorDialog); };
        const open404ErrorDialog = () => { openDialog(Render404ErrorDialog); };
        const open401ErrorDialog = () => { openDialog(Render401ErrorDialog); };
        const open403ErrorDialog = () => { openDialog(Render403ErrorDialog); };
        const fetcher = new FetchWithStandardErrorHandling({
          handleNetworkError: openNetworkErrorDialog,
          handle500Error: open500ErrorDialog,
          handle404Error: open404ErrorDialog,
          handle401Error: open401ErrorDialog,
          handle403Error: open403ErrorDialog,
          getCsrfInfo: () => props.csrfInfo
        });
        const serialFetcher = new FetchWithSerialOperationHandling(startSerialOperation, fetcher);

        const context: IAppContext = {
          registerContextMenu,
          fetcher,
          serialFetcher,
          openDialog,
          confirm,
          flashMessage,
          registerBeforeUnload,
          setWindowLocationHref
        };

        return props.renderChildren(context);
      }} />
    </ErrorBoundary>
  );
}