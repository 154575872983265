import * as React from "react";
import { SiteHeader } from "spry-react/Page/siteHeader";
import { SiteNavigation } from "spry-react/Page/siteNavigation";
import { Link } from "spry-react/Link/link";

import './portalFullHeader.less';

export interface Props {
  homeUrl: string;
  homeLinkText: string;
  logoImageUrl: string;
  bordered?: boolean;
  navContent?: React.ReactNode;
  children?: React.ReactNode;
}

export class PortalFullHeader extends React.Component<Props> {
  render() {
    const props = this.props;
    return <div>
      <section id="site-top" className="clearfix">
      <div className="portal-site-links">
        <Link url={props.homeUrl} className='site-header-button home-icon'>
          {props.homeLinkText}
        </Link>
        </div>
        <div className='portal-site-buttons'>
          <div className="site-header-right-buttons">
            <SiteNavigation siteLinksContent={<></>} navContent={this.props.navContent} />
          </div>
        </div>
    </section>
      <SiteHeader homeUrl={props.homeUrl} bordered={props.bordered} logoImageUrl={props.logoImageUrl}>{props.children}</SiteHeader>
    </div>;
  }
}