import * as React from 'react';

import { PhoneNumber, LabeledPhoneField } from 'spry-react/Phone/phoneField';
import { Dialog } from 'spry-react/Dialog/dialog';
import { ErrorDisplayListAutoScrolling } from 'spry-react/Errors/errorDisplayListAutoScrolling';
import { ErrorList } from 'spry-react/Errors/errorList';
import { toSelectFieldOptions } from 'spry-react/utils/ListUtils';
import { LabeledTextField } from 'spry-react/Text/textField';
import { Link } from 'spry-react/Link/link';
import { NamedCode, KeyedCode } from 'spry-react/utils/apiTypes';
import { Address } from 'spry-react/Address/addressField';
import { ZipCodesApiClient } from 'spry-react/Address/zipCodesApiClient';

import { PureGU1Field, PureG, Field } from 'Components/Shared/pureG';
import { AccountsApiClient, PortalUserType, UpdateProfileRequest } from './accountsApiClient';
import { ChangePasswordDialog } from './changePasswordDialog';
import { AdvocateAndAttorneyFields, AdvocateAndAttorneyData } from './advocateAndAttorneyFields';
import { IAppContext } from "Components/Shared/appContext";
import { YesNoField } from 'spry-react/YesNo/yesNoField';
import { FieldLabel } from 'spry-react/Label/fieldLabel';

interface Props {
  context: IAppContext;
  accountsApiClient: AccountsApiClient;
  closeDialog: () => void;
  initialData: Data;
  zipCodesApiClient: ZipCodesApiClient;
  states: KeyedCode[];
}

interface State {
  data: Data;
  errors: ErrorList;
}

interface Data {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: PhoneNumber;
  userType: PortalUserType;
  assistingOrganization: string;
  address: Address;
  firmName: string;
  fax: PhoneNumber;
  receivePortalTaskNotificationDigest: boolean;
  taxId: string;
}

export class UpdateProfileDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      data: props.initialData,
      errors: new ErrorList([])
    };
  }

  render() {
    const data = this.state.data;
    const errors = this.state.errors;

    return <Dialog width="50em" singleTabText="Update Profile">
      <div>
        <ErrorDisplayListAutoScrolling errors={errors} />
        <PureGU1Field>
          <LabeledTextField required autoFocus label="First Name"
            value={data.firstName}
            onChange={this.onFirstNameChanged}
            errorMessage={errors.getErrorMessage("FirstName")}
          />
        </PureGU1Field>
        <PureGU1Field>
          <LabeledTextField label="Middle Name"
            value={data.middleName}
            onChange={this.onMiddleNameChanged}
          />
        </PureGU1Field>
        <PureGU1Field>
          <LabeledTextField required label="Last Name"
            value={data.lastName}
            onChange={this.onLastNameChanged}
            errorMessage={errors.getErrorMessage("LastName")}
          />
        </PureGU1Field>
        <PureGU1Field>
          <LabeledTextField required label="Email"
            value={data.email}
            onChange={this.onEmailChanged}
            errorMessage={errors.getErrorMessage("Email")} />
        </PureGU1Field>
        <PureGU1Field>
          <LabeledPhoneField required label="Phone"
            phone={data.phone}
            onPhoneChange={this.onPhoneChanged}
            errorMessages={errors.getErrorMessages("Phone")} />
        </PureGU1Field>
        <AdvocateAndAttorneyFields
          userType={data.userType}
          states={this.props.states}
          zipCodesApiClient={this.props.zipCodesApiClient}
          data={data}
          onChange={this.onAttorneyAdvocateDataChanged}
          errors={errors}
        />
        <PureGU1Field>
          <FieldLabel 
            text={
            <>
              Receive Portal Task Notification Digest
              <span className='info-circle-icon l-box-hl' title='Receive a nightly digest of all portal task notifications, instead of receiving them individually.'></span>
            </>
            }
            for="receivePortalTaskNotificationDigest" 
          />
          <YesNoField 
            id="receivePortalTaskNotificationDigest"
            value={data.receivePortalTaskNotificationDigest} 
            onToggle={() => { this.updateData(d => { return {...d, receivePortalTaskNotificationDigest: !d.receivePortalTaskNotificationDigest }}) }} />
        </PureGU1Field>
      </div>
      <PureG>
        <Field className='action-buttons'>
          <button className='pure-button pure-button-transparent' onClick={this.props.closeDialog}>Cancel</button>
          <button className='pure-button pure-button-primary' onClick={this.onSaveAccountClicked}>Save Profile</button>
        </Field>
      </PureG>
      <div>
        You can also <Link onClick={this.onChangePasswordClicked}>change your password</Link>.
      </div>
    </Dialog>;
  }

  private updateData = (producer: (data : Data) => Data ) => {
    return this.setState(state => {
      return { data: producer(state.data) };
    });
  }

  private onFirstNameChanged = (firstName: string) => this.updateData((data) => { return { ...data, firstName: firstName }; });
  private onMiddleNameChanged = (middleName: string) => this.updateData((data) => { return { ...data, middleName: middleName }; });
  private onLastNameChanged = (lastName: string) => this.updateData((data) => { return { ...data, lastName: lastName }; });
  private onEmailChanged = (value: string) => this.updateData((data) => { return { ...data, email: value }; });
  private onPhoneChanged = (phone: PhoneNumber) => this.updateData((data) => { return { ...data, phone: phone }; });

  private onAttorneyAdvocateDataChanged = (data: AdvocateAndAttorneyData) => {
    this.updateData(state => {
      return {
        ...state,
        assistingOrganization: data.assistingOrganization,
        address: data.address,
        firmName: data.firmName,
        fax: data.fax,
        taxId: data.taxId
      };
    });
  }

  private onSaveAccountClicked = () => {
    const data = this.state.data;
    const request : UpdateProfileRequest = {
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      userType: data.userType,
      assistingOrganization: data.assistingOrganization,
      address: data.address,
      firmName: data.firmName,
      fax: data.fax,
      taxId: data.taxId,
      receivePortalTaskNotificationDigest: data.receivePortalTaskNotificationDigest
    };
    this.props.accountsApiClient.updateProfile(request).then(
      response => {
        if (!response.success) {
          this.setState({ errors: new ErrorList(response.errors) });
        }
        else {
          this.props.context.flashMessage("Profile updated");
          this.props.closeDialog();
        }
      }
    )
  }

  private onChangePasswordClicked = () => {
    this.props.accountsApiClient.getChangePasswordInfo().then(
      response => {
        this.props.context.openDialog(closeDialog => {
          return <ChangePasswordDialog
            closeDialog={closeDialog}
            passwordPolicyRules={response.passwordPolicyRules}
            accountsApiClient={this.props.accountsApiClient}
            flashMessageCallback={this.props.context.flashMessage} />;
        });
      }
    );
  }
}