import * as React from "react";
import { LabeledTextField } from "spry-react/Text/textField";
import { PortalUserType, AddressSuggestionState } from "./accountsApiClient";
import { Address } from "spry-react/Address/addressField";
import { PhoneNumber, LabeledPhoneField } from "spry-react/Phone/phoneField";
import { ErrorList } from "spry-react/Errors/errorList";
import { ZipCodesApiClient } from "spry-react/Address/zipCodesApiClient";
import { KeyedCode } from "spry-react/utils/apiTypes";
import { LabeledSelectField, SelectFieldOption } from "spry-react/Select/selectField";
import { getAddressSuggestion } from 'spry-react/Address/addressSuggestion';
import { PureGU1Field, Field, PureG } from "Components/Shared/pureG";

export interface AdvocateAndAttorneyData {
  assistingOrganization: string; // adv only
  address: Address; // both adv/attorney
  firmName: string;  // atty only
  fax: PhoneNumber;  // atty only
  taxId: string;  // atty only
}

export interface Props {
  zipCodesApiClient: ZipCodesApiClient;
  states: KeyedCode[];
  userType: PortalUserType;
  data: AdvocateAndAttorneyData;
  onChange: (data: AdvocateAndAttorneyData) => void;
  errors: ErrorList;
}

export interface State {
  addressSuggestionState: AddressSuggestionState | null;
}

export class AdvocateAndAttorneyFields extends React.Component<Props, State> {
  private emptyAddressSuggestionState: AddressSuggestionState = {
    cities: [],
    states: [],
    lastDefaults: {
      lastDefaultedCity: '',
      lastDefaultedState: '',
      lastDefaultedCounty: '',
      lastDefaultedCountry: ''
    }
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      addressSuggestionState: this.emptyAddressSuggestionState
    };
  }

  render() {
    const { userType, errors } = this.props;
    const { assistingOrganization, address, firmName, fax, taxId } = this.props.data;
    const { addressSuggestionState } = this.state;

    const cityOptions = addressSuggestionState
    ? addressSuggestionState.cities.map(c => { return { value: c, text: c }; })
    : [];
    const stateOptions = addressSuggestionState
      ? addressSuggestionState.states.map(s => { return { value: s.state, text: s.stateName }; })
      : [{ value: "", text: "" }].concat(this.props.states.map(s => { return { value: s.systemKey, text: s.name }; }));

      const addressFields = <>
      <PureGU1Field>
        <LabeledTextField
          label='Street Address'
          value={address.street}
          onChange={this.onAddressStreetChanged}
          required
          errorMessage={errors.getErrorMessage("Address.Street")} />
      </PureGU1Field>
      <PureG>
        <Field size='1-4'>
          <LabeledTextField
            label='Zip'
            value={address.zipCode}
            onChange={this.onAddressZipChanged}
            onBlur={this.onZipCodeBlur}
            required
            errorMessage={errors.getErrorMessage("Address.ZipCode")} />
        </Field>
        <Field size='1-2'>
          <LabeledSelectField
            label="City"
            value={address.city ? { value: address.city, text: address.city } : null}
            options={cityOptions}
            allowCustomInput
            onChange={this.onAddressCityChanged}
            errorMessage={errors.getErrorMessage('Address.City')} />
        </Field>
        <Field size='1-4'>
          <LabeledSelectField
            label='State'
            value={address.state ? { value: address.state, text: address.state } : null}
            options={stateOptions}
            onChange={this.onAddressStateChanged}
            required
            errorMessage={errors.getErrorMessage('Address.State')} />
        </Field>
      </PureG>
    </>;

    return <>
      {userType === "Advocate" && <>
          <PureGU1Field>
            <LabeledTextField
              label='Assisting Organization'
              value={assistingOrganization}
              onChange={this.onAssistingOrganizationChanged}
              required
              errorMessage={errors.getErrorMessage("AssistingOrganization")} />
          </PureGU1Field>
          {addressFields}
      </>}

      {userType === "Attorney" && <>
        <PureGU1Field>
          <LabeledTextField
            label='Firm Name'
            value={firmName}
            onChange={this.onFirmNameChanged}
            errorMessage={errors.getErrorMessage('FirmName')} />
        </PureGU1Field>
        {addressFields}
        <PureG>
          <Field size='1-2'>
            <LabeledPhoneField
              label='Fax'
              phone={fax}
              onPhoneChange={this.onFaxChanged}
              errorMessages={errors.getErrorMessages('Fax')} />
          </Field>
          <Field size='1-2'>
            <LabeledTextField
              label='Tax ID or SSN'
              required
              value={taxId}
              onChange={this.onTaxIdChanged}
              errorMessage={errors.getErrorMessage('TaxId')} />
          </Field>
        </PureG>
      </>}
    </>;
  }

  private onAssistingOrganizationChanged = (value: string) => {
    this.props.onChange({ ...this.props.data, assistingOrganization: value });

  }
  private onAddressStreetChanged = (value: string) => {
    this.props.onChange({ ...this.props.data, address: { ...this.props.data.address, street: value } });
  }
  private onAddressZipChanged = (value: string) => {
    this.props.onChange({ ...this.props.data, address: { ...this.props.data.address, zipCode: value } });
  }

  private onZipCodeBlur = () => {
    this.autoFillAddressSuggestions(this.props.data.address.zipCode);
  }

  private autoFillAddressSuggestions(zipCode: string) {
    getAddressSuggestion(zipCode, (zipCode: string) => {
        return this.props.zipCodesApiClient.getAddressSuggestion(zipCode, () => {/* silently fail here */});
      },
      this.props.data.address,
      this.state.addressSuggestionState.lastDefaults)
    .then(suggestion => {
      const lastDefaults = this.state.addressSuggestionState.lastDefaults;

      this.setState(state => {
        return {
          addressSuggestionState: {
            cities: suggestion.cities,
            states: suggestion.states,
            lastDefaults: {
              lastDefaultedCity: suggestion.defaultCity ? suggestion.city : lastDefaults.lastDefaultedCity,
              lastDefaultedState: suggestion.defaultState ? suggestion.state : lastDefaults.lastDefaultedState,
              lastDefaultedCountry: '',
              lastDefaultedCounty: ''
            }
          }
        };
      }, () => {
          this.props.onChange({
            ...this.props.data, address: {
              ...this.props.data.address,
              city: suggestion.defaultCity ? suggestion.city : lastDefaults.lastDefaultedCity,
              state: suggestion.defaultState ? suggestion.state : lastDefaults.lastDefaultedState
            }
          });
      });
    });
  }

  private onAddressCityChanged = (selectedOption: SelectFieldOption) => {
    this.props.onChange({ ...this.props.data, address: { ...this.props.data.address, city: selectedOption.value } });
  }
  private onAddressStateChanged = (selectedOption: SelectFieldOption) => {
    this.props.onChange({ ...this.props.data, address: { ...this.props.data.address, state: selectedOption.value } });
  }
  private onFirmNameChanged = (value: string) => {
    this.props.onChange({ ...this.props.data, firmName: value });
  }
  private onFaxChanged = (faxNumber: PhoneNumber) => {
    this.props.onChange({ ...this.props.data, fax: faxNumber });
  }
  private onTaxIdChanged = (value: string) => {
    this.props.onChange({ ...this.props.data, taxId: value });
  }
}